<template>
    <el-dropdown
        class="SwitchPhone"
        trigger="click"
        placement="bottom-start"
        @command="SelectPhone"
        @visible-change="VisibleSwitchPhone"
    >
        <!-- SwitchPhone reference -->
        <button
            class="SwitchPhone__Reference"
            type="button"
            :class="{ 'SwitchPhone__Reference-Visible': SwitchPhoneVisibility }"
        >
            <div class="SwitchPhone__Current-Value">
                <span
                    class="SwitchPhone__Reference-Flag"
                    :style="{
                        backgroundImage: 'url('+require(`assets/img/flags/${phoneOutput.region_code}.svg`)+')'
                    }"
                />

                <span class="SwitchPhone__Reference-Label">
                    {{ phoneOutput.region_code }}
                </span>
            </div>

            <img
                class="SwitchPhone__Icon"
                src="~assets/img/ChevronDown.svg"
            >
        </button>

        <!-- SwitchPhone menu -->
        <el-dropdown-menu
            slot="dropdown"
            class="SwitchPhone__menu SwitchPhone__menu--no-label"
            transform-origin="left top"
            :visible-arrow="false"
            :append-to-body="true"
        >
            <el-dropdown-item
                v-for="(Phone, PhoneID) in phoneMasks"
                :key="PhoneID"
                :command="PhoneID"
            >
                <div
                    class="SwitchPhone__Reference-Flag"
                    :style="{
                        backgroundImage: 'url('+require(`assets/img/flags/${Phone.region_code}.svg`)+')'
                    }"
                />

                <span class="SwitchPhone__Reference-Label">
                    {{ Phone.region_code }}
                </span>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'SwitchPhone',
        props: [
            'value'
        ],

        data() {
            return {
                SwitchPhoneVisibility: false,
                SwitchPhoneOutputDefault: {
                    id: 1,
                    mask: '+7 (###) ###-##-##',
                    placeholder: '+7 (999) 999-99-99',
                    region_code: 'ru'
                }
            }
        },

        computed: {
            ...mapGetters('auth', ['phoneMasks']),

            phoneOutput: function() {
                return this.phoneMasks.find((variable) => variable.id === this.value.id) || this.SwitchPhoneOutputDefault;
            }
        },

        methods: {
            SelectPhone(index) {
                this.$emit('onChangeMask', this.phoneMasks[index]);
            },

            VisibleSwitchPhone(visible) {
                this.SwitchPhoneVisibility = visible;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .SwitchPhone {
        &.el-dropdown {
            min-width: 115px;
            max-width: 115px;
            margin-right: 5px;
        }
        //Reference Button
        &__Reference {
            display: flex;
            align-items: center;
            padding: 11px;
            border: 1px solid #E0E0E0;
            box-shadow: none;
            border-radius: 12px;
            outline: none;
            cursor: pointer;
            margin: 0;
            width: 100%;
            background: none;
            position: relative;

            @media (max-width: 1024px) {
                padding: 9px;
            }

            &_cursor {
                cursor: default;
            }

            //Reference Flag
            &-Flag {
                min-width: 32px;
                min-height: 32px;
                max-width: 32px;
                max-height: 32px;
                border-radius: 50%;
                display: block;
                margin-right: 8px;
                background-size: cover;
                background-position: 50%;
                box-shadow: 0 0 16px 0.2px rgba(0, 0, 0, 0.1);

                @media (max-width: 1024px) {
                    min-width: 26px;
                    min-height: 26px;
                    max-width: 26px;
                    max-height: 26px;
                }
            }

            &-Label {
                text-transform: uppercase;
            }

            &-Visible {
                border-radius: 10px 10px 0 0;
            }
        }

        &__Current-Value {
            display: flex;
            align-items: center;
            color: #606266;
            text-transform: uppercase;
        }

        // Menu
        &__menu {
            &.el-dropdown-menu {
                padding: 0 0 0 0;
                border: none;
                background: #fff;
                border: 1px solid #E0E3F0;
                box-shadow: 0 20px 20px rgba(0, 0, 0, 0.08);
                overflow: hidden;
                overflow-y: auto;
                max-height: 400px;
                min-width: 300px - 0px;
                max-width: 300px - 0px;
                border-radius: 0 0 10px 10px;
                z-index: 999999 !important;
                &.el-popper {
                    &[x-placement^=bottom] {
                        margin: 0;
                        border-top-width: 0;
                    }

                    &[x-placement^=top-start] {
                        margin: 0;
                        border-bottom-width: 0;
                        border-radius: 10px 10px 0 0;
                        box-shadow: 0 -20px 20px rgba(0, 0, 0, 0.08);
                    }
                }

                //Design ScrollBar
                &::-webkit-scrollbar {
                    width: 8px;
                }
                &::-webkit-scrollbar-track {
                    background: rgba(0,0,0,.015);
                }
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(10, 132, 255, .5);
                    border-radius: 8px;
                }
                &:hover::-webkit-scrollbar-thumb {
                    background-color: rgba(10, 132, 255, 1);
                }
                &:hover::-webkit-scrollbar-track {
                    background: rgba(0,0,0,.05);
                }

                //Item
                .el-dropdown-menu {
                    &__item {
                        line-height: 100%;
                        padding: 8px 16px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        &:focus, &:not(.is-disabled):hover {
                            background-color: #ecf5ff;
                            color: #66b1ff;
                        }

                        span {
                            display: block;
                            margin-right: 8px;
                            &:last-child {
                              margin-right: 0;
                            }
                        }
                    }
                }
            }

            &--no-label {
                &.el-dropdown-menu {
                    min-width: 115px;
                }
            }
        }

        &__Icon {
            right: 10px;
            position: absolute;
        }
    }
</style>
